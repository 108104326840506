import './coupon-entry.css'
import Cookies from 'universal-cookie'

/* dependent to checkout-page component */

const resetSubmit = (selector) => {
  const submit = document.querySelector(`${selector}__submit`)
  submit.disabled = false
  submit.innerHTML = 'Apply'
  return false
}

const disableSubmit = (selector) => {
  const submit = document.querySelector(`${selector}__submit`)
  submit.disabled = true
  if (submit.dataset.customDisableWith) submit.innerHTML = submit.dataset.customDisableWith
}

document.addEventListener('DOMContentLoaded', () => {
  const selector = '.c-coupon-entry'
  const container = document.querySelector(selector)
  if (container === null) return

  document.querySelector(`${selector}__promo`).addEventListener('click', () => {
    document.querySelector(`${selector}__promo`).remove()
    showCouponForm(selector)
  })

  document.querySelector(`${selector}__remove`).addEventListener('click', () => {
    removeCoupon(selector)
  })

  document.querySelector(`${selector}__form`).addEventListener('submit', (e) => {
    e.preventDefault()
    handleSubmission(selector, e.target)
  })

  if (document.querySelector(`${selector}__text-input`).value !== '') {
    document.querySelector(`${selector}__promo`).remove()
    preloadCoupon(selector)
  }
})

const preloadCoupon = (selector) => {
  document.querySelector(`${selector}__form`).classList.remove('hide')
  document.querySelector(`${selector}__submit`).click()
}

const showCouponForm = (selector) => {
  document.querySelector(`${selector}__form`).classList.remove('hide')
  document.querySelector(`${selector}__text-input`).focus()
}

const handleSubmission = (selector, form) => {
  disableSubmit(selector)
  fetch(stringifyUrl(form.action, formData(selector)), {
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    method: 'GET',
  })
    .then((response) => response.json())
    .then((data) => {
      window.data = data
      if (typeof data.error === 'undefined') processData(data, selector)
      else processInvalid(selector, data)
      resetSubmit(selector)
    })
    .catch(() => {
      processServerError(selector)
      resetSubmit(selector)
    })
}

const price = (selector) => {
  return parseFloat(document.querySelector(`${selector}__price`).value).toFixed(2)
}

const formData = (selector) => {
  const code = document.querySelector(`${selector}__text-input`).value
  return `code=${code}&price=${price(selector)}`
}

const processData = (data, selector) => {
  const newPrice = price(selector) - data.amount
  setCouponCookie(data.code)
  showPricing('.c-checkout-page', newPrice, data.amount)
  setSuccessMessage(selector, data.msg, data.amount)
  setErrorMessage(selector, '')
  updatePaymentForm(data.code, newPrice)
}

const setCouponCookie = (code) => {
  const cookies = new Cookies()
  if (code !== null) cookies.set('coupon_code', code, { path: '/' })
  else cookies.remove('coupon_code', { path: '/' })
}

const updatePaymentForm = (code, price) => {
  const codeElems = document.querySelectorAll('.c-stripe-form__code, .c-applepay-form__code')
  codeElems.forEach(function (item) {
    item.value = code
  })

  const priceElems = document.querySelectorAll('.c-stripe-form__price, .c-applepay-form__price')
  priceElems.forEach(function (item) {
    item.value = price
  })

  if (price === 0) {
    const hideElems = document.querySelectorAll('.c-stripe-form__stripe-section, .c-payment-section__express-checkout')
    for (let i = 0; i < hideElems.length; i++) {
      hideElems[i].classList.add('hide')
    }
  }
}

const resetPaymentForm = () => {
  const codeElems = document.querySelectorAll('.c-stripe-form__code, .c-applepay-form__code')
  codeElems.forEach(function (item) {
    item.value = null
  })

  const priceElems = document.querySelectorAll('.c-stripe-form__price, .c-applepay-form__price')
  priceElems.forEach(function (item) {
    item.value = null
  })

  const hideElems = document.querySelectorAll('.c-stripe-form__stripe-section, .c-payment-section__express-checkout')
  for (let i = 0; i < hideElems.length; i++) {
    hideElems[i].classList.remove('hide')
  }
}

const showPricing = (selector, newPrice) => {
  document.querySelector(`${selector}__total-js`).classList.add('hide')
  document.querySelector(`${selector}__new-total-js`).classList.remove('hide')
  const price = newPrice === 0 ? 0 : newPrice.toFixed(2)
  document.querySelector(`${selector}__new-total-js ${selector}__pricing-line-amount`).innerHTML = `$${price}`
}

const resetPricing = (selector) => {
  document.querySelector(`${selector}__total-js`).classList.remove('hide')
  document.querySelector(`${selector}__new-total-js`).classList.add('hide')
  document.querySelector(`${selector}__coupon-result-js`).classList.add('hide')
}

const setErrorMessage = (selector, message) => {
  document.querySelector(`${selector}__errors span`).innerHTML = message
}

const resetSuccessMessage = (selector) => {
  const altSelector = '.c-checkout-page'
  const removeButton = document.querySelector(`${selector}__success button`)
  document.querySelector(`${altSelector}__coupon-result-js`).classList.add('hide')
  removeButton.classList.add('hide')
  document.querySelector(`${altSelector}__coupon-result-js ${altSelector}__pricing-line-text`).innerHTML = ''
  document.querySelector(`${altSelector}__coupon-result-js ${altSelector}__pricing-line-amount`).innerHTML = ''
}

const setSuccessMessage = (selector, message, amount) => {
  const altSelector = '.c-checkout-page'
  document.querySelector(`${altSelector}__coupon-result-js`).classList.remove('hide')
  document.querySelector(`${altSelector}__coupon-result-js ${altSelector}__pricing-line-text`).innerHTML = message
  document.querySelector(
    `${altSelector}__coupon-result-js ${altSelector}__pricing-line-amount`
  ).innerHTML = `$${amount.toFixed(2)}`
  const removeButton = document.querySelector(`${selector}__success button`)
  removeButton.classList.remove('hide')
}

const processInvalid = (selector, data) => {
  setCouponCookie(null)
  resetSuccessMessage(selector)
  setErrorMessage(selector, data.error)
}

const removeCoupon = (selector) => {
  resetSuccessMessage(selector)
  document.querySelector(`${selector}__text-input`).value = ''
  setErrorMessage(selector, '')
  setCouponCookie(null)
  resetPricing('.c-checkout-page')
  resetPaymentForm()
}

const processServerError = (selector) => {
  setCouponCookie(null)
  document.querySelector(`${selector}__errors span`).innerHTML =
    'We encountered an error, please try refreshing the page'
}

const stringifyUrl = (url, data) => {
  return `${url}?${data}`
}
